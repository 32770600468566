import { ThirdPartyListEntityModel, ThirdPartyListFilterModel } from '@common-src/entity-model/third-party-list-entity';

import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';

// const URL_PATH = `${MESSAGE_BASE_REQUEST_PATH.slice(0, -8)}/clientApp`;
const URL_PATH = `/infra-auth/api/client`;

class ThirdPartlistService implements ICRUDQ<ThirdPartyListEntityModel, ThirdPartyListFilterModel> {
    async create(model: ThirdPartyListEntityModel):Promise<ThirdPartyListEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<ThirdPartyListEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new ThirdPartyListEntityModel().toModel(res);
    }

    async update(model: ThirdPartyListEntityModel):Promise<ThirdPartyListEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: ThirdPartyListEntityModel):Promise<ThirdPartyListEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: ThirdPartyListFilterModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new ThirdPartyListEntityModel().toModel(item));
        res.items = _.map(res.items, (item, index) => ({ index: index + 1, ...item }));
        return res;
    }

    async getOptions() {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page: 1, limit: 9999 }, new ThirdPartyListFilterModel().toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new ThirdPartyListEntityModel().toModel(item));
        res.items = _.map(res.items, (item) => ({ name: item.clientName, value: item.clientId }));
        return res.items;
    }
}

export default new ThirdPartlistService();
