
import { BaseEntityModel } from '@common-src/model/base-model';
import { ColumnProps } from 'ant-design-vue/lib/table';
import { FormControl, FormControlType } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';

export class ThirdPartyListEntityModel extends BaseEntityModel {
    @FormControl({
        label: '服务商名称',
        type: FormControlType.TEXT,
        required: true
    })
    clientName: string = null;

    @FormControl({
        label: '服务商标识',
        type: FormControlType.TEXT,
        required: true
    })
    clientId: string = null;

    @FormControl({
        label: '服务商密钥',
        type: FormControlType.TEXT,
        required: true
    })
    clientSecret: string = null;

    @FormControl({
        label: '鉴权方式',
        type: FormControlType.SELECT,
        required: true,
        options: [
            { label: '标准', value: 'OAUTH' },
            { label: '自有', value: 'NIMBUS' }
        ]
    })
    authType: any = undefined;

    @FormControl({
        label: '后端服务地址',
        type: FormControlType.TEXT,
        required: true
    })
    serviceUrl: string = null;

    @FormControl({
        label: 'web端地址',
        type: FormControlType.TEXT,
        required: true
    })
    domainUrl: string = null;

    @FormControl({
        label: '移动端地址',
        type: FormControlType.TEXT,
        required: true
    })
    mobileUrl: string = null;

    @FormControl({
        label: 'token发放策略',
        type: FormControlType.SELECT,
        required: true,
        options: [
            { label: '在发放端缓存,未失效的情况下使用缓存token', value: 'USE_CACHE' },
            { label: '每次申请重新发放，每分钟内的申请token的次数限制默认值为5', value: 'ALWAYS_CREATE' }
        ]
    })
    flowControl: any = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA,
        required: false
    })
    description: string = null;

    static getTableColumns(): ColumnProps[] {
        return [
            {
                title: '序号',
                dataIndex: 'index'
            },
            {
                title: '服务商名称',
                dataIndex: 'clientName',
                width: 120
            },
            {
                title: '服务商标识',
                dataIndex: 'clientId'
            },
            {
                title: '服务商秘钥',
                dataIndex: 'clientSecret'
            },
            {
                title: '鉴权方式',
                dataIndex: 'authType',
                customRender: (text) => {
                    return text === 'OAUTH' ? '标准' : '自有';
                }
            },
            {
                title: '后端服务地址',
                dataIndex: 'serviceUrl'
            },
            {
                title: 'web端地址',
                dataIndex: 'domainUrl'
            },
            {
                title: '移动端地址',
                dataIndex: 'mobileUrl'
            },
            {
                title: '描述',
                dataIndex: 'description'
            },
            {
                title: '操作',
                dataIndex: 'action',
                width: 180,
                scopedSlots: { customRender: 'action' }
            }
        ];
    }
}

export class ThirdPartyListFilterModel extends QueryPageModel {
    @QueryControl({
        label: '服务商名称',
        type: QueryControlType.TEXT
    })
    clientName: string = '';

    @QueryControl({
        label: '服务商标识',
        type: QueryControlType.TEXT
    })
    clientId: string = null;

    toService() {
        const data:any = {};
        data.params = {
            clientName: this.clientName || null,
            clientId: this.clientId || null
        };
        return data;
    }
}
